<template>
    <div>
        基本资料
        <input type="text">
    </div>
</template>

<script>
export default {
    name: 'userinfo',
    data() {
        return {

        }
    },

}
</script>

<style scoped>

</style>
